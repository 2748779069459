<template>
  <div class="loginmodal">
    <div class="joinwrap">
      <!--img class="jointopimg" src="@/assets/img/sign_up_icon.png"-->
      <h3 class="join-title">{{ $t('front.common.signup')}}</h3>
      <p class="jointtxt">{{ $t('front.signup.warning1') }}<br>{{ $t('front.signup.warning2') }}</p>
      <div class="joininwrap">
        <div>
          <ul class="joinu">
            <li>{{ $t('front.common.memId') }}</li>
            <li><input type="text" :placeholder="$t('front.signup.memIdPlaceholder')" name="signupMemId" v-model="model.memId" :readonly="true" @focus="onFocus"></li>
          </ul>
          <ul class="joinu">
            <li>{{ $t('front.common.nickName') }}</li>
            <li><input type="text" :placeholder="$t('front.signup.nickNamePlaceholder')"  name="signupMemNick" v-model="model.memNick" ::readonly="true" @focus="onFocus"></li>
          </ul>
          <ul class="joinu">
            <li>{{ $t('front.common.password') }}</li>
            <li><input type="password" :placeholder="$t('front.signup.passwordPlaceholder')" name="signupMemName" v-model="model.memPass" :readonly="true" @focus="onFocus"></li>
          </ul>
          <ul class="joinu">
            <li>{{ $t('front.common.passwordCheck') }}</li>
            <li><input type="password" :placeholder="$t('front.signup.passwordPlaceholder')" v-model="model.memPassCheck" :readonly="true" @focus="onFocus"></li>
          </ul>
        </div>
        <div>
          <ul class="joinu">
            <li>{{ $t('front.common.cashOutPass') }}</li>
            <li><input id="cashOutPass" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" type="text" maxlength="4" :placeholder="$t('front.signup.cashOutPassPlaceholder')" pattern="[0-9]*" v-model="model.cashOutPass" inputmode="numeric" /></li>
          </ul>
          <ul class="joinu">
            <li>{{ $t('front.common.phone') }}</li>
    <!--        <li class="w100">-->
    <!--          <select v-model="model.memPhoneVendor">-->
    <!--            <option value="skt">SKT</option>-->
    <!--            <option value="kt">KT</option>-->
    <!--            <option value="lgu">LGU</option>-->
    <!--            <option value="sktmvno">SKT(알뜰폰)</option>-->
    <!--            <option value="ktmvno">KT(알뜰폰</option>-->
    <!--            <option value="lgumvno">LGU(알뜰폰)</option>-->
    <!--          </select>-->
    <!--        </li>-->
            <li class="joinu"><input type="text" :placeholder="$t('front.signup.phonePlaceholder')" v-model="model.memPhone"></li>
          </ul>
          <ul class="joinu">
            <li>{{ $t('front.common.recommandId') }}</li>
            <li><input type="text" :placeholder="$t('front.signup.recommandIdPlaceholder')" v-model="model.recommenderId"></li>
          </ul>
          <ul class="joinu">
            <li>{{ $t('front.common.bankAcc') }}</li>
            <li class="w96">
              <select v-model="model.bank">
                <option selected :value="''" disabled>{{ $t('front.common.bankSelect') }}</option>
                <template v-for="item in bankList" v-bind:key="item.bankCode">
                  <option :value="item.bankCode" >{{ item.bankNameKr }}</option>
                </template>
              </select>
            </li>
            <li class="w100"><input type="text" :placeholder="$t('front.common.bankName')" v-model="model.memName"></li>
            <li class="w158"><input type="text" :placeholder="$t('front.common.bankAccOnlyNumber')" v-model="model.bankacc" oninput="this.value = this.value.replace(/[^0-9.]/g, '')"></li>
          </ul>
        </div>
      </div>
      <a class="loginnbtn on" @click="onSubmit">{{ $t('front.common.signup') }}</a>
      <!--ul class="snswrap" v-if="siteOption">
        <li><img src="@/assets/img/kakao.png"><span class="br">{{siteOption['sns2Id']}}</span></li>
        <li><img src="@/assets/img/tele.png"><span class="bl">{{siteOption['sns1Id']}}</span></li>
      </ul-->
      <div class="loginclose" @click="onClose"><img src="@/assets/img/icon_cancel.png" /></div>
    </div>
  </div><!-- join -->
</template>

<script>

import { getBankList, signUp } from '@/api/member'
import { isValidOnlyNumber, isValidOnlyPhoneNumber, isValidPassword, isValidUserId } from '@/libs/utils'
import { mapState } from 'vuex'

export default {
  name: 'Join',
  computed: {
    ...mapState([
      'siteOption'
    ])
  },
  watch: {

  },
  created () {
    this.loadBankList()
  },
  methods: {
    loadBankList () {
      getBankList({}).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          const data = result.data
          this.bankList = data.list
        }
      })
    },
    isValidate () {
      const data = this.model
      for (const key of Object.keys(data)) {
        const value = data[key]
        if (key === 'memId') {
          if (value === '') {
            this.onAlert('warningart', 'front.member.emptyMemId')
            return false
          }

          if (!isValidUserId(value)) {
            this.onAlert('warningart', 'api.U101')
            return false
          }
        }

        if (key === 'memPassCheck') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.compareMemPass')
            return false
          }
        }

        if (key === 'memPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemPass')
            return false
          }

          if (!isValidPassword(value) || value.length > 20) {
            this.onAlert('warningart', 'api.U102')
            return false
          }
        }

        if (key === 'cashOutPass') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyCashOutPass')
            return false
          }

          if (!isValidOnlyNumber(value) || value.length !== 4) {
            this.onAlert('warningart', 'api.U103')
            return false
          }
        }

        if (key === 'memNick') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemName')
            return false
          }

          if (value.length > 20 || value.length < 3) {
            this.onAlert('warningart', 'api.U104')
            return false
          }
        }

        if (key === 'memPhone') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyMemTel')
            return false
          }

          if (!isValidOnlyPhoneNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidMemTel')
            return false
          }
        }

        if (key === 'recommenderId') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyRecommenderId')
            return false
          }
        }

        if (key === 'bank') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankName')
            return false
          }
        }

        if (key === 'memName') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankAccountName')
            return false
          }
        }

        if (key === 'bankacc') {
          if (data[key] === '') {
            this.onAlert('warningart', 'front.member.emptyBankNumber')
            return false
          }

          if (!isValidOnlyNumber(value)) {
            this.onAlert('warningart', 'front.member.noValidBankNumber')
            return false
          }
        }

        if (data.memPass !== data.memPassCheck) {
          this.onAlert('warningart', 'front.member.compareMemPass')
          return false
        }
      }

      return true
    },
    onClose () {
      this.$emit('close', false)
    },
    onSubmit (nextPageName) {
      if (this.isValidate()) {
        this.model.memPhone = this.model.memPhone.toString()
        signUp(this.model).then(async response => {
          const result = response.data
          if (result.resultCode === '0') {
            await this.onCheck('front.member.completeSignup')
            if (nextPageName && typeof nextPageName === 'string') {
              this.goPageByName(nextPageName)
            } else {
              this.$emit('close', true)
            }
          } else {
            this.onAlert('warningart', 'api.' + result.resultCode)
          }
        }).catch(err => {
          console.error('singup err : ', err)
        })
      }
    }
  },
  data () {
    return {
      bankList: [],
      checkMemPass: '',
      model: {
        siteName: '',
        siteType: '',
        ip: '',
        apiKey: '',
        memId: '',
        memPass: '',
        memPassCheck: '',
        memPhone: '',
        memPhoneVendor: '',
        memLevel: '1',
        bank: '',
        memName: '',
        memNick: '',
        bankacc: '',
        cashOutPass: '',
        nationCode: '',
        partSendYn: 'N',
        maxCredit: '',
        creditStatus: '',
        firstBetConfYn: 'N',
        commissionGroup: '',
        recommenderId: '',
        partnerType: '',
        memStatus: 0,
        membetType: ''
      }
    }
  }
}
</script>

<style scoped>
#cashOutPass {
  -webkit-text-security: disc;
}
</style>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
